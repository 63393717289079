exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-properties-grev-55-tsx": () => import("./../../../src/pages/properties/grev55.tsx" /* webpackChunkName: "component---src-pages-properties-grev-55-tsx" */),
  "component---src-pages-properties-index-tsx": () => import("./../../../src/pages/properties/index.tsx" /* webpackChunkName: "component---src-pages-properties-index-tsx" */),
  "component---src-pages-properties-market-tsx": () => import("./../../../src/pages/properties/market.tsx" /* webpackChunkName: "component---src-pages-properties-market-tsx" */),
  "component---src-pages-properties-roadmap-tsx": () => import("./../../../src/pages/properties/roadmap.tsx" /* webpackChunkName: "component---src-pages-properties-roadmap-tsx" */),
  "component---src-pages-properties-tokenomics-tsx": () => import("./../../../src/pages/properties/tokenomics.tsx" /* webpackChunkName: "component---src-pages-properties-tokenomics-tsx" */)
}

