import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { useEffect, useRef } from 'react';

export const Divider = styled(Box)<{ height: string; background?: string }>`
  height: ${({ height = '1em' }) => height};
  background: ${({ background }) => background};
`;

export const gridSize = ({
  colCount,
  colWidth,
  gutter,
}: {
  colCount: number;
  colWidth: number;
  gutter: number;
}) => (colWidth + gutter) * colCount;

export const Anchor = styled(Box)<{ topDistance?: string }>`
  position: absolute;
  top: ${({ topDistance }) => topDistance || '-10em'};
  left: 0;
`;

export const loadFonts = (
  fonts: Array<string>,
  action: (val?: boolean) => void,
) => {
  const promises: Promise<unknown>[] = [];
  fonts.forEach((font) => promises.push(document.fonts.load('12px ' + font)));

  Promise.all(promises).then(() => action());
};

export function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useIntersectionObserver(
  elementRef: Element | null,
  intersects: () => void,
  intersectsNot: () => void,
) {
  try {
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].boundingClientRect.y < 0) {
          intersects();
        } else {
          intersectsNot();
        }
      });
      if (elementRef) observer.observe(elementRef);
    }
  } catch (error) {
    // do nothing
  }
}

export function checkIsFullscreen() {
  const docAsAny = document as any;
  const fullScreenElement =
    docAsAny.fullscreenElement ||
    docAsAny.webkitFullscreenElement ||
    docAsAny.mozFullscreenElement;
  const isFullscreen =
    fullScreenElement !== null && fullScreenElement !== undefined;
  return isFullscreen;
}

function onFullscreenExit(callback?: () => void) {
  const docAsAny = document as any;

  if (docAsAny.addEventListener) {
    docAsAny.addEventListener('fullscreenchange', exitHandler, false);
    docAsAny.addEventListener('mozfullscreenchange', exitHandler, false);
    docAsAny.addEventListener('MSFullscreenChange', exitHandler, false);
    docAsAny.addEventListener('webkitfullscreenchange', exitHandler, false);
  }

  function exitHandler() {
    if (
      !docAsAny.webkitIsFullScreen &&
      !docAsAny.mozFullScreen &&
      !docAsAny.msFullscreenElement
    ) {
      if (callback) callback();
    }
  }
}

export function launchFullscreen(elem: unknown, callback?: () => void) {
  if (!elem) return;
  const elemChecker = elem as any;

  onFullscreenExit(callback);

  try {
    // Checking the availability of functions through different browsers
    switch (true) {
      case !!elemChecker.requestFullscreen:
        elemChecker.requestFullscreen();
        break;
      case !!elemChecker.mozRequestFullScreen:
        elemChecker.mozRequestFullScreen();
        break;
      case !!elemChecker.webkitRequestFullscreen:
        elemChecker.webkitRequestFullscreen();
        break;
      case !!elemChecker.msRequestFullscreen:
        elemChecker.msRequestFullscreen();
        break;

      default:
        break;
    }
  } catch (e: unknown) {
    if (e instanceof Error) console.log('Couldnt enter fullscreen', e.message);
    if (typeof e === 'string')
      console.log('Couldnt enter fullscreen', e.toUpperCase());
  }
}

export function exitFullscreen() {
  const docAsAny = document as any;
  const isFullscreen = checkIsFullscreen();

  // Exit fullscreen
  if (isFullscreen) {
    try {
      if (docAsAny.webkitExitFullscreen) {
        docAsAny.webkitExitFullscreen();
      }
      if (docAsAny.mozCancelFullscreen) {
        docAsAny.mozCancelFullscreen();
      }
      if (docAsAny.exitFullscreen) {
        docAsAny.exitFullscreen();
      }
    } catch (e: any) {
      console.log('Couldnt exit fullscreen', e.message);
    }
  }
}

export const getPrimaryLink = (input: string) => {
  let removedFirstSlash;
  if (!input) return '';
  if (input === '/') return input;
  if (input[0] !== '/') removedFirstSlash = input;

  removedFirstSlash = input.slice(1);
  return removedFirstSlash.split('/')[0];
};
