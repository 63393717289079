import { Theme, responsiveFontSizes } from '@mui/material';
import { createTheme, ComponentsOverrides } from '@mui/material/styles';
import shadows from './shadows';
import { light, dark } from './palette';
import PoppinsBoldTtf from '../fonts/Poppins-Bold.ttf';
import PoppinsSemiBoldTtf from '../fonts/Poppins-SemiBold.ttf';
import PoppinsRegularTtf from '../fonts/Poppins-Regular.ttf';
import RobotoBoldTtf from '../fonts/Roboto-Bold.ttf';
import RobotoRegularTtf from '../fonts/Roboto-Regular.ttf';
import { gridSize } from 'utils';

export const customFontList: { [K: string]: '*.ttf' } = {
  'Poppins Regular': PoppinsRegularTtf,
  'Poppins SemiBold': PoppinsSemiBoldTtf,
  'Poppins Bold': PoppinsBoldTtf,
  'Roboto Bold': RobotoBoldTtf,
  'Roboto Regular': RobotoRegularTtf,
};

export const RobotoFontSize = '18px';

const addCustomFonts = () => {
  let fontFaces = '';
  Object.keys(customFontList).forEach((font) => {
    fontFaces += `@font-face {
          font-family: '${font}';
					font-display: "swap";
          src: local('${font}'), url(${customFontList[font]}) format('truetype');
        }; `;
  });
  return fontFaces;
};

const getTheme = (mode: string, themeToggler: () => void): Theme =>
  responsiveFontSizes(
    createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 910,
          maxContent: 1056,
          lg: 1200,
          xl: 1536,
        },
      },
      palette: mode === 'light' ? light : dark,
      shadows: shadows(mode),
      typography: {
        button: {
          textTransform: 'none',
          fontWeight: 'medium' as React.CSSProperties['fontWeight'],
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: addCustomFonts(),
        },
        MuiButton: {
          styleOverrides: {
            root: {
              // fontWeight: 400,
              // borderRadius: 5,
              // paddingTop: 10,
              // paddingBottom: 10,
              borderRadius: '30px',
              padding: '0.7em 2em',
              marginRight: '1.8em',
              boxShadow: 'unset',
            },
            containedSecondary: mode === 'light' ? { color: 'white' } : {},
          } as ComponentsOverrides['MuiButton'],
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
          } as ComponentsOverrides['MuiInputBase'],
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
            input: {
              borderRadius: 5,
            },
          } as ComponentsOverrides['MuiOutlinedInput'],
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          } as ComponentsOverrides['MuiCard'],
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              '::before': {
                backgroundColor: 'unset',
              },
            },
          } as ComponentsOverrides['MuiAccordion'],
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              color: mode === 'light' ? '#F8F8F8' : '#364454',
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              cursor: 'pointer',
              textDecoration: 'unset',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          },
        },
      },
      themeToggler,
    }),
  );

export default getTheme;

export const siteWidth = { colCount: 12, colWidth: 72, gutter: 16 };
export const propertiesActiveWidth = { colCount: 12, colWidth: 96, gutter: 18 };

export const sizeOfGrid = gridSize(siteWidth);
