import React from 'react';
import { CacheProvider } from '@emotion/react';
import getEmotionCache from './getEmotionCache';
import { navigate } from 'gatsby';

const cache = getEmotionCache();

export const wrapRootElement = ({ element }) => {
  return <CacheProvider value={cache}>{element}</CacheProvider>;
};

export const onInitialClientRender = () => {
  // document.onreadystatechange = function () {
  //   if (document.readyState !== 'complete') {
  //     document.querySelector('#___gatsby').style.display = 'none';
  //     document.querySelector('#___loader').style.visibility = 'visible';
  //   } else {
  //     document.querySelector('#___loader').style.display = 'none';
  //     document.querySelector('#___gatsby').style.display = 'block';
  //   }
  // };
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const { pathname, hash } = location;
  const prevPathname = prevLocation ? prevLocation.pathname : null;
  const prevHash = prevLocation ? prevLocation.hash : null;
  const navURL = pathname + hash;
  const prevNavURL = prevPathname + prevHash;

  if (navURL !== prevNavURL) checkHash(location);
};

const checkHash = (location) => {
  const { hash, pathname } = location;
  if (hash) {
    navigate(pathname + hash);
  }
};
